<!-- =========================================================================================
    File Name: TodoEdit.vue
    Description: Edit todo component
    ----------------------------------------------------------------------------------------
    Item Name: Legal Board Services
      Author: Enkompass
    Author URL: https://www.enkompass.net
========================================================================================== -->


<template>
    <vs-popup
        :title="modalTitle"
        :buttons-hidden="true"
        @close="init"
        :is-valid="validateForm"
        :active.sync="deletePrompt">
        <div>
            <form>
                <div class="vx-row mb-5">
                  <div class="vx-col w-full mb-5 text-sm">
                    Are you sure you want to delete this {{fixType}} ?
                  </div>
                  <div class="vx-col w-full">
                    <vs-checkbox color="success"
                                 v-if="activeType === 'accounts'"
                                 v-model="accountDeleteConfirm"
                                 class="text-sm"
                    >
                      I understand that deleting the law firm will delete all
                      of its associated data (cases, users) as well.
                    </vs-checkbox>
                  </div>
                  <div v-if="confirmRequired" class="w-full vx-col required-field text-xs">
                    <sup>*</sup>This field is required
                  </div>
                </div>
            </form>
            <footer>
              <button :disabled="processing === 1" id="delete-frm-btn" class="vs-con-loading__container vs-component vs-button vs-dialog-accept-button vs-button-danger vs-button-filled mr-4" @click="execute" name="button">Yes</button>
              <button :disabled="processing === 1" class="vs-component vs-button vs-dialog-cancel-button vs-button-primary vs-button-border" @click.stop="closeModal" name="button">No</button>
            </footer>
        </div>
    </vs-popup>
</template>

<script>
export default {
  props: {
    displayDeletePrompt: false,
    taskId: {
      required: true
    },
    activeType: {
      required: true
    }
  },
  data () {
    return {
      fieldData: {
        accounts : {name : 'Law Firm Name'}, //, encryptionKey: 'Encryption Key (optional)'
        cases : {name : 'Case Name'},
        users : {name : 'Full Name'} // uuid: 'Email Address'
      },
      processing : 0,
      accountDeleteConfirm: false,
      confirmRequired: false
    }
  },
  created() {

  },
  computed: {
    deletePrompt: {
      get () {
        return this.displayDeletePrompt
      },
      set (value) {
        return this.$emit('hideDisplayPrompt', {shouldRefresh: false, menuType: this.activeType})
      }
    },
    validateForm () {
    },
    menuType () {
      return this.activeType
    },
    modalTitle () {

      if(this.fixType === 'firm'){
        return 'Delete Law Firm'
      }

      if(this.fixType === 'user'){
        return 'Delete User'
      }

      return 'Delete ' + this.fixType
    },
    fixType (){
      if(this.menuType === 'accounts'){
         return 'firm'
      }

      if(this.menuType === 'users'){
         return 'user'
      }

      return this.menuType
    }
  },
  watch: {
    accountDeleteConfirm(val) {
      this.confirmRequired = false
    }
  },
  methods: {
    closeModal () {
      this.deletePrompt = 0
    },
    execute (){
      let isValid = this.activeType !== 'accounts';

      if (!isValid) {
        isValid = this.accountDeleteConfirm
      }

      if (isValid) {
        this.processing = 1;

        this.$vs.loading({
          container: "#delete-frm-btn",
          scale: 0.45
        })

        this.$store.dispatch('todo/deleteData', this.taskId)
          .then(response => {
            this.processing = 0
            this.$vs.loading.close('#delete-frm-btn > .con-vs-loading')
            this.$emit('hideDisplayPrompt', {shouldRefresh: true, menuType: this.activeType})
          }).catch(err => {
            let message = "Delete Failed";
            if (err && err.message) message = err.message;
            else if (err && err.response && err.response.data && err.response.data.message) message = err.response.data.message;
            else if (err && err.data && err.data.message) message = err.data.message;
            this.processing = 0
            this.$vs.loading.close('#delete-frm-btn > .con-vs-loading')
            this.$vs.notify({
              color: 'danger',
              title: 'Error',
              text: message
            })
          });
      } else {
        this.confirmRequired = 'danger'
      }
    },
    removeTodo () {
    },
    init () {
    },
    submitTodo () {
    }
  }
}
</script>

<style>
  .con-slot-label {
    padding: 0 8px;
  }
  .required-field {
    color: rgb(234, 84, 85);
  }
</style>
